import { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { Alert } from 'bf-ui';

import styles from './globalEmitter.wrapper.scss';

import globalEmitter, { emits } from '../../../emitter/global.emitter';

import ModalsEnum from '../../../enums/modals.enum';
import { getOffer_req } from '../../../api/offers.api';
import KachaOTPModal from '../../modals/kachaOTP/kachaOTP.modal';
import PaymentOTPModal from '../../modals/paymentOTP/paymentOTP.modal';

const FreeSpinsModal = dynamic(
  () => import('../../modals/freeSpins/freeSpins.modal'),
  {
    loading: () => <></>,
  }
);

const FirstDepositModal = dynamic(
  () => import('../../modals/firstDeposit/firstDeposit.modal'),
  {
    loading: () => <></>,
  }
);

const NewTournamentsModal = dynamic(
  () => import('../../modals/newTournaments/newTournaments.modal'),
  {
    loading: () => <></>,
  }
);

const WheelModal = dynamic(() => import('../../modals/wheel/wheel.modal'), {
  loading: () => <></>,
});

const OfferModal = dynamic(() => import('../../modals/offer/offer.modal'), {
  loading: () => <></>,
});

const TournamentModal = dynamic(
  () => import('../../modals/tournament/tournament.modal'),
  {
    loading: () => <></>,
  }
);

const LuckyWheelModal = dynamic(
  () => import('../../modals/lucky-wheel/luckyWheel.modal'),
  {
    loading: () => <></>,
  }
);

export default function GlobalEmitterWrapper() {
  const { asPath, locale } = useRouter();
  const intl = useIntl();

  const offerTimeoutRef = useRef();

  const [alertQueue, setAlertQueue] = useState([]);
  const [modalQueue, setModalQueue] = useState([]);

  const checkForOffer = useCallback(async () => {
    const newOffer = await getOffer_req(null, asPath.replace('/', ''), locale);

    if (newOffer) {
      offerTimeoutRef.current = setTimeout(() => {
        globalEmitter.emit(emits.MODAL, {
          id: ModalsEnum.OFFER,
          data: newOffer,
        });
      }, 2000);
    }
  }, [asPath, locale]);

  const onAlert = useCallback(
    (data) => {
      const queue = [...alertQueue];
      data.id = Date.now();
      queue.push(data);
      setAlertQueue(queue);
    },
    [alertQueue]
  );

  const onModal = useCallback(
    (data) => {
      const queue = [...modalQueue];
      queue.push(data);
      setModalQueue(queue);
      if (!document.body.classList.contains('lock')) {
        document.body.classList.add('lock');
      }
    },
    [modalQueue]
  );

  const onAlertDisappear = () => {
    const queue = [...alertQueue];
    queue.shift();
    setAlertQueue(queue);
  };

  const onModalClose = (id) => {
    document.body.classList.remove('lock');
    const queue = [...modalQueue];
    setModalQueue(queue.filter((data) => data.id !== id));
  };

  const getAlertTitleByType = useCallback(
    (type) => {
      switch (type) {
        case 'error':
          return intl.messages.error;
        case 'success':
          return intl.messages.success;
        case 'warning':
          return intl.messages.warning;
        case 'info':
          return intl.messages.information;
        default:
          return '';
      }
    },
    [intl]
  );

  useEffect(() => {
    checkForOffer();
  }, [asPath]);

  useEffect(() => {
    globalEmitter.on(emits.ALERT, onAlert);
    globalEmitter.on(emits.MODAL, onModal);

    return () => {
      globalEmitter.removeListener(emits.ALERT, onAlert);
    };
  }, [alertQueue]);

  const renderModal = (data) => {
    const onClose = () => onModalClose(data.id);

    switch (data.id) {
      case ModalsEnum.FREE_SPINS:
        return <FreeSpinsModal data={data.data} onClose={onClose} />;
      case ModalsEnum.FIRST_DEPOSIT:
        return <FirstDepositModal data={data.data} onClose={onClose} />;
      case ModalsEnum.NEW_TOURNAMENTS:
        return <NewTournamentsModal data={data.data} onClose={onClose} />;
      case ModalsEnum.WHEEL:
        return <WheelModal data={data.data} onClose={onClose} />;
      case ModalsEnum.OFFER:
        return <OfferModal data={data.data} onClose={onClose} />;
      case ModalsEnum.TOURNAMENT:
        return <TournamentModal data={data.data} onClose={onClose} />;
      case ModalsEnum.LUCKY_WHEEL:
        return <LuckyWheelModal data={data.data} onClose={onClose} />;
      case ModalsEnum.KACHA_OTP:
        return <KachaOTPModal data={data.data} onClose={onClose} />;
      case ModalsEnum.PAYMENT_OTP:
        return <PaymentOTPModal data={data.data} onClose={onClose} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      {alertQueue?.length ? (
        <div className={classnames('alerts', alertQueue[0].anchor)}>
          <Alert
            title={getAlertTitleByType(alertQueue[0].type)}
            severity={alertQueue[0].type}
            disappearTimeout={7000}
            onDisappear={() => onAlertDisappear(alertQueue[0].id)}
          >
            {alertQueue[0].message}
          </Alert>
        </div>
      ) : (
        <></>
      )}
      {modalQueue?.length ? renderModal(modalQueue[0]) : <></>}
      <style jsx>{styles}</style>
    </>
  );
}
