import { useState } from 'react';
import { Button, Modal, TextField } from 'bf-ui';

import styles from './paymentOTP.modal.scss';
import globalEmitter, { emits } from '../../../emitter/global.emitter';
import { sendPaymentOTP_req } from '../../../api/payment.api';

export default function PaymentOTPModal({ data, onClose }) {
  const [OTP, setOTP] = useState('');

  const [OTPLoading, setOTPLoading] = useState(false);
  const [otpError, setOtpError] = useState(false);

  const onOTPChange = (e) => {
    setOTP(e.target.value);
  };

  const onSend = async () => {
    if (OTPLoading) {
      return;
    }
    if (!OTP) {
      setOtpError(true);
      return;
    }
    setOtpError(false);
    try {
      setOTPLoading(true);
      const response = await sendPaymentOTP_req(
        data.operatorID,
        data.reference,
        data.paymentID,
        OTP
      );

      globalEmitter.emit(emits.ALERT, {
        type: 'success',
        message: response?.message || `Your deposit was successful!`,
      });
    } catch (e) {
      if (e?.response?.data?.message) {
        globalEmitter.emit(emits.ALERT, {
          type: 'error',
          message: e.response.data.message,
        });
      } else {
        globalEmitter.emit(emits.ALERT, {
          type: 'error',
          message: `Something went wrong! Please contact our support.`,
        });
      }
    }
    setOTPLoading(false);

    onClose();
  };

  return (
    <>
      <Modal className="otp_modal" onClose={onClose}>
        <TextField
          className="otp_input"
          placeholder="Code"
          error={otpError}
          value={OTP}
          onChange={onOTPChange}
          helperText={
            otpError ? 'Code is required!' : 'Enter your code from SMS here'
          }
        />
        <Button
          variant="contained"
          onClick={onSend}
          disabled={OTPLoading}
          loading={OTPLoading}
        >
          OK
        </Button>
      </Modal>
      <style jsx>{styles}</style>
    </>
  );
}
